module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@_oehslh5fycfy6azutoksjjxihq/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","nl","de","fr","es"],"defaultLanguage":"en","siteUrl":"https://i18nweave.com","trailingSlash":true,"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":":","defaultNS":"common","ns":["common","navigation"]},"pages":[{"matchPath":"/:lang?","getLanguageFromPath":false}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-fontawesome-css@1.2.0_@fortawesome+fontawesome-svg-core@6.6.0_gatsby@5.13.7_bab_agnqw6t4i4mslmt3p6vdf7ov7e/node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__j3nwtvwgkwanxqfskc63nnmlhm/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
